import React from "react";
import ReactDOM from 'react-dom';
import "./index.css";
import './fonts.css';
import App from "./App";
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from "react-router-dom";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirectUri:'https://buyitdev.yourhomesy.com/getting-started', // https://buyitdev.yourhomesy.com
      }}
      // {...auth0config}
    >
      <Router>
        <App />
      </Router>     
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

