/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Loader from "../common/Loader";

function BuyerSignComplete() {
  const [propertyData, setPropertyData] = useState({});
  const [builderInfo, setBuilderInfo] = useState({});
  const [builderConfig, setBuilderConfig] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getBuilderInfo = async () => {
    let builderValue = localStorage.getItem("builderInfo");
    builderValue = JSON.parse(builderValue);
    setBuilderInfo(builderValue);
  }



  const getBuilderConfig = async () => {
    let builderConfigValue = localStorage.getItem("builderConfig");
    builderConfigValue = JSON.parse(builderConfigValue);
    setBuilderConfig(builderConfigValue);
  }
  const getPropertyData = async () => {
    let propertyValue = localStorage.getItem("propertyInfo");
    propertyValue = JSON.parse(propertyValue);
    setPropertyData(propertyValue);
  }

  const getUserData = async () => {
    let userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    setUserInfo(userData);
  }

useEffect(() => {
  const processData = async () => {
    await getBuilderInfo();
          await getBuilderConfig();
          await getPropertyData();
          await getUserData();
          setIsLoading(false);
  };

  processData();
}, []);


  return isLoading? ( <Loader /> ) : (
    <div className="min-h-screen flex flex-col items-center">
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[144px] items-center mt-10">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]"></div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <img
              src={builderConfig?.logo}
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">
              Powered by Homesy.
            </p>
          </div>

        </div>
      </div>
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[167px] items-center">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">

              <img
                src={
                  propertyData?.Image_Urls?.length > 0
                    ? propertyData?.Image_Urls
                    : "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                }
                alt={propertyData?.propertyId}
                className="w-[530px] h-[530px] rounded-[20px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            {/* <div className="last-icon mb-[25px]">
              <img src="./assets/images/new-home.svg" alt="homeicon" className="" />
            </div> */}

            <h1 className="font-circular text-[54px] font-[450] text-center mb-[25px] text-[#112F50]">
            Congrats! <br/> Your home is nearly yours.
            </h1>
            <p className="font-circular text-[22px] font-[450] text-center w-[572px] mb-[60px] text-[#112F50]">
            We’ll be following up with next steps to confirm the purchase of your new home.
            </p>
            <div className="flex flex-col items-center space-y-6 w-full pl-5 pr-5">
              <div className="text-center">
                <a
                  href="/"
                  className="font-circular text-[18px] md:text-[22px] font-[450] text-center w-full md:w-[572px] text-[#112F50]"
                >
                  Back to listing - (see pending status of their house)
                </a>
              </div>
            </div>
            {/* <div className="flex flex-col items-center space-y-6 w-full pl-5 pr-5">
              <div className="w-full text-center">
                <button
                  type="button"
                  className={`start-btn font-circular text-[18px] text-white font-[450] bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] focus:outline-none focus:ring-2 focus:ring-offset-2 `}
                >
                  Finalize profile for notifications
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {propertyData?.property_line?.length > 0
                ? propertyData?.property_line
                : "305 Bennett Way"}
            </p>
            <p className="font-circular text-customGray text-[17px]">
              {propertyData?.property_address?.length > 0
                ? propertyData?.property_address
                : "Willow Ridge, Georgia 30132"}
            </p>
            <a className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]">
              See details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyerSignComplete;
