import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../common/Loader";

function LoginPage() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const data = urlParams.get("data");
  const navigate = useNavigate();

  

  const getBuilderInfo = async (id) => {
    try {
      const builderResponse = await axios.get(`${apiBaseUrl}/builders/${id}`);
      console.log('builderResponse', builderResponse);
      if (builderResponse.status === 200) {
        console.log('builderResponse', builderResponse.data.data.builder);
        localStorage.setItem("builderInfo", JSON.stringify(builderResponse.data.data.builder));
        // setBuilderInfo(builderResponse.data.data.builder);        
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const arrayToObject = (arr) =>  {
    return arr.reduce((acc, curr) => {
      acc[curr.elementName] = curr.elementValue;
      return acc;
    }, {});
  }

  const getBuilderConfig = async (id) => {
    try {
      const builderConfigResponse = await axios.get(`${apiBaseUrl}/builderconfig/builder/${id}`);
      console.log('builderConfigResponse', builderConfigResponse);
      if (builderConfigResponse.status === 200) {       
        let data = arrayToObject(builderConfigResponse.data.data);
        localStorage.setItem("builderConfig", JSON.stringify(data));
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const secretKey = "homesy"; // Same secret key used for encryption

useEffect(() => {
  const processDecryptedData = async () => {
    if (!data || data.length === 0) return;
    try {
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData) {
           // Only fetch builder info if builderID exists
        if (decryptedData?.builderID) {
          await getBuilderInfo(decryptedData.builderID);
          await getBuilderConfig(decryptedData.builderID);
          // setIsLoading(false);
          if (isAuthenticated) {
            navigate("/user-info");
          } else {
            loginWithRedirect();
          }


        }

        // Save data to localStorage
        localStorage.setItem("propertyInfo", JSON.stringify(decryptedData));
      }
    } catch (error) {
      console.error('Error decrypting or processing data:', error);
    }
  };

  processDecryptedData();
}, [data]);

//   const login = () => {
//     if (isAuthenticated) {
//       navigate("/user-info");
//     } else {
//       loginWithRedirect();
//     }
//   };
  return (
    <div><Loader/></div>
  )
}

export default LoginPage