/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { GoogleMap, useLoadScript, Autocomplete } from "@react-google-maps/api";
import ProgressBar from "./ProgressBar";
import axios from "axios";
import Loader from "../common/Loader";

const UserInfoComponent = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth0();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const libraries = ["places"];
  const autocompleteRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 6;
  const [builderInfo, setBuilderInfo] = useState({});
  const [location, setLocation] = useState();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: user?.email,
    phone: "",
    address: "",
    city: "",
    pincode: "",
    country: "USA",
    state: "",
    address2: "",
    agent: "",
    userId: user?.sub,
  });
  const [errors, setErrors] = useState({}); // State for errors
  const [builderConfig, setBuilderConfig] = useState({});
  const [propertyData, setPropertyData] = useState({});

  const getBuilderInfo = async () => {
    let builderValue = localStorage.getItem("builderInfo");
    builderValue = JSON.parse(builderValue);
    setBuilderInfo(builderValue);
  };

  const getBuilderConfig = async () => {
    let builderConfigValue = localStorage.getItem("builderConfig");
    builderConfigValue = JSON.parse(builderConfigValue);
    setBuilderConfig(builderConfigValue);
  };
  const getPropertyData = async () => {
    let propertyValue = localStorage.getItem("propertyInfo");
    propertyValue = JSON.parse(propertyValue);
    setPropertyData(propertyValue);
  };

  useEffect(() => {
    const processData = async () => {
      await getBuilderInfo();
      await getBuilderConfig();
      await getPropertyData();
      setIsLoading(false);
    };

    processData();
  }, []);

  const validate = (dataObject) => {
    const errors = {};
    if (!dataObject.firstName && step === 2) {
      errors.firstName = "First name is required";
    }
    if (!dataObject.lastName && step === 2) {
      errors.lastName = "Last name is required";
    }

    if (!dataObject.email && step === 3) {
      errors.email = "Email is required";
    } else if (step === 3 && !/\S+@\S+\.\S+/.test(dataObject.email)) {
      errors.email = "Email address is invalid";
    }
    if (!dataObject.phone && step === 4) {
      errors.phone = "Phone number is required";
    }

    // if (!dataObject.city && step === 4) {
    //   errors.city = "City is required";
    // }
    // if (!dataObject.pincode && step === 4) {
    //   errors.pincode = "Postal Code is required";
    // }
    // if (!dataObject.state && step === 4) {
    //   errors.state = "State is required";
    // }
    if (!dataObject.address && step === 5) {
      errors.address = "Address is required";
    }
    if (!dataObject.agent && step === 1) {
      errors.agent = "Agent is required";
    }
    if (step === 5 && !termsAccepted) {
      errors.terms = "You must agree to the terms and conditions";
    }
    return errors;
  };

  const handleNext = () => {
    const validationErrors = validate(userInfo);
    setErrors(validationErrors);
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setStep(step + 1);
      if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (step === 1) {
      navigate("/getting-started");
    } else if (step > 1) {
      setStep(step - 1);
      setCurrentStep(currentStep - 1);
    } else {
      // window.location.replace("/");
      navigate(-1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => {
      const { [name]: removedError, ...restErrors } = prevErrors;
      return restErrors;
    });
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validate(userInfo);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    let newaddress = userInfo.address.split(',')[0];
    try {
      const userData = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phoneNumber: userInfo.phone,
        addressLine1: newaddress,
        city: userInfo.city,
        state: userInfo.state,
        postalCode: userInfo.pincode,
        country: userInfo.country,
        addressLine2: userInfo.address2,
        userid: userInfo.userId,
      };
      console.log("userData", userData);
      const userResponse = await axios.post(`${apiBaseUrl}/buyers`, userData);
      console.log("user response", userResponse);
      if (userResponse.status === 201) {
        let userInfoData = userInfo;
        userInfoData.address = newaddress;
        localStorage.setItem("userInfo", JSON.stringify(userInfoData));
          navigate("/contract");
        // const propertyBuyerData = {
        //   buyerid: userInfo.userId,
        //   propertyid: propertyData.propertyid,
        //   agentname: userInfo.agent,
        //   builderagentid: "",
        //   buyeraddress: userInfo.address,
        //   builderemail: builderInfo.email,
        //   nextstep: 3,
        //   status: false,
        // };
        // const propertyBuyerRes = await axios.post(
        //   `${apiBaseUrl}/propertybuyers`,
        //   propertyBuyerData
        // );
        // if (propertyBuyerRes.status === 201) {
        //   localStorage.setItem("userInfo", JSON.stringify(userInfo));
        //   navigate("/contract");
        // }
        // console.log("property buyer response", propertyBuyerRes);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    if (input.length > 0) {
      formattedPhoneNumber = "(" + input.substring(0, 3);
    }
    if (input.length >= 4) {
      formattedPhoneNumber += ") " + input.substring(3, 6);
    }
    if (input.length >= 7) {
      formattedPhoneNumber += "-" + input.substring(6, 10);
    }

    setUserInfo({ ...userInfo, phone: formattedPhoneNumber });
    setErrors((prevErrors) => {
      const { phone: removedError, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);

    // Remove error for terms acceptance if checked
    setErrors((prevErrors) => {
      const { terms, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBIQ-xrDX4IyOqjCpqRn0DghT7kwVopACY", // Your API key
    libraries,
  });

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.address_components) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;

      const getComponent = (type) => {
        const component = addressComponents.find(comp => comp.types.includes(type));
        return component ? component.long_name : '';
      };

      setUserInfo({
        ...userInfo,
        address: formattedAddress,
        city: getComponent('locality'),
        state: getComponent('administrative_area_level_1'),
        pincode: getComponent('postal_code'),
      });

      setLocation(formattedAddress);
    }
  };

  const skipHandler = () => {
    setStep(2);
  };

  const getPropertyInfo = async (propertyId) => {
    try {
      const propertyRes = await axios.get(
        `${apiBaseUrl}/properties/${propertyId}`
      );
      if (propertyRes.status === 200 && Object.keys(propertyData).length > 0) {
        console.log("propertyRes", propertyRes);
        if (Object.keys(propertyRes.data.data).length === 0) {
          const response = await axios.post(
            `${apiBaseUrl}/properties`,
            propertyData
          );
        }
        // setPropertyData(propertyRes.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (propertyData?.propertyid) {
      getPropertyInfo(propertyData.propertyid);
    }
  }, [propertyData]);
  return isLoading ? (
    <Loader />
  ) : (
    <div className="min-h-screen flex flex-col items-center">
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[192px] items-center mt-10">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]"></div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <img
              src={builderConfig?.logo}
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">
              Powered by Homesy.
            </p>
          </div>
        </div>
      </div>
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[192px] items-center">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">
              <button
                type="button"
                onClick={handlePrevious}
                className="py-[20px] bg-white text-gray-700 text-[17px] font-semibold rounded-full flex items-center justify-center"
              >
                <img
                  src="./assets/images/back-arrow-icon.svg"
                  alt="back-icon"
                />{" "}
                Back to all homes
              </button>
              <img
                src={
                  propertyData?.Image_Urls?.length > 0
                    ? propertyData?.Image_Urls
                    : "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                }
                alt={propertyData?.propertyId}
                className="w-[530px] h-[530px] rounded-[20px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <form
              className="flex flex-col input-placeholder items-center w-full"
              onSubmit={handleSubmit}
            >
              {step === 2 && (
                <>
                  <ProgressBar currentStep={2} />
                  <div className="mb-[70px]">
                    <h1 className="font-circular text-[54px] font-[450] text-center text-[#112F50] mb-[11px] leading-[63.25px]">
                    First, what's your name?
                    </h1>
                    <h5 className="font-circular text-[22px] md:text-2xl font-[450] text-customGray leading-[27.83px]">
                      Please use your full legal name.
                    </h5>
                  </div>

                  <div className="w-full flex flex-col gap-4">
                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={userInfo.firstName}
                        onChange={handleChange}
                        className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm text-left">
                          {errors.firstName}
                        </p>
                      )}
                    </div>

                    {/* Last Name Field */}
                    <div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={userInfo.lastName}
                        onChange={handleChange}
                        className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm text-left">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {step === 3 && (
                <>
                  <ProgressBar currentStep={3} />
                  <div className="mb-[70px]">
                    <h1 className="font-circular text-[54px] font-[450] mb-[11px] text-center text-[#112F50] leading-[63.25px]">
                    Best email to send stuff?
                    </h1>
                    <h5 className="font-circular text-[22px] md:text-2xl font-[450] text-[#112F50] leading-[27.83px]">
                      {" "}
                      This is where we'll send a copy of your contract
                    </h5>
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Email address"
                      name="email"
                      value={userInfo.email}
                      onChange={handleChange}
                      className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm text-left">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  <ProgressBar currentStep={4} />
                  <div className="mb-[70px]">
                    <h1 className="font-circular text-[54px] mb-[11px] font-[450] text-center text-[#112F50] leading-[63.25px]">
                      Good phone number?
                    </h1>
                    <h5 className="font-circular text-[22px] md:text-2xl  font-[450] text-[#112F50] leading-[27.83px]">
                      We won't call unless we have to.
                    </h5>
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="(XXX) XXX - XXXX"
                      name="phone"
                      value={userInfo.phone}
                      onChange={handlePhoneChange}
                      maxLength={14}
                      className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
                    />
                    {errors.phone && (
                      <p className="w-full text-red-500 text-sm text-left">
                        {errors.phone}
                      </p>
                    )}
                  </div>
                </>
              )}

              {step === 5 && (
                <>
                  <ProgressBar currentStep={5} />
                  <div className="mb-[70px]">
                    <h1 className="font-circular text-[48px] mb-[11px] font-[450] text-center  text-[#112F50]  leading-[63.25px]">
                      Your current address.
                    </h1>
                    <h5 className="font-circular text-[22px] md:text-2xl font-[450] text-[#112F50] leading-[27.83px]">
                      {" "}
                      Can be a home, rental or even your parent's place.
                    </h5>
                  </div>

                  <div className="w-full mb-[18px]">
                    <Autocomplete
                      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                      onPlaceChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        placeholder="Enter your full address"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
                      />
                    </Autocomplete>
                    {errors.address && (
                      <p className="text-red-500 text-sm text-left mt-2">
                        {errors.address}
                      </p>
                    )}
                  </div>

                  {/* Hidden inputs for city, state, and pincode */}
                  <input type="hidden" name="city" value={userInfo.city} />
                  <input type="hidden" name="state" value={userInfo.state} />
                  <input type="hidden" name="pincode" value={userInfo.pincode} />

                  <div className="flex flex-col items-start w-full mt-[31px]">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="terms"
                        className="mr-2"
                        checked={termsAccepted}
                        onClick={handleTermsChange}
                      />
                      <label
                        htmlFor="terms"
                        className="text-customGray font-circular leading-[20.24px] text-[16px]"
                      >
                        I agree to the{" "}
                        <a
                          href="/T&C"
                          className={`text-[${builderConfig?.brandColor}] underline font-circular  leading-[20.24px] text-[16px]`}
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>
                    {errors.terms && (
                      <p className="text-red-500 text-sm mt-2">
                        {errors.terms}
                      </p>
                    )}
                  </div>
                </>
              )}

              {step === 1 && (
                <>
                  <ProgressBar currentStep={1} />
                  <div className="mb-[70px]">
                    <h1 className="font-circular text-[48px] mb-[11px] font-[450] text-center text-customGray leading-[63.25px]">
                      Did a sales agent help you <br /> find this house?
                    </h1>
                    <h5 className="font-circular text-[22px] md:text-2xl font-[450] text-customGray leading-[27.83px]">
                      {" "}
                      Find this house on your own?{" "}
                      <a
                        href="#"
                        className={`text-[${builderConfig?.brandColor}] underline font-circular text-[22px] md:text-2xl font-[450] leading-[27.83px]`}
                        onClick={skipHandler}
                      >
                        Skip sales agent.
                      </a>
                    </h5>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full mb-[18px]">
                    <div className="flex flex-col space-y-4 relative">
                      <input
                        type="text"
                        placeholder="Sales agent name"
                        name="agent"
                        value={userInfo.agent}
                        onChange={handleChange}
                        className="w-full px-4 py-3 text-[22px] text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3] pr-[130px]" // Adjust padding for the text to avoid overlap
                      />
                      <span className={`absolute right-4 top-[20%] translate-y-[-50%] cursor-pointer m-0 text-[${builderConfig?.brandColor}] font-[450] font-circular text-[16px] `}>
                        Search directory
                      </span>
                      {errors.agent && (
                        <p className="text-red-500 text-sm text-left">
                          {errors.agent}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="flex w-full pt-[60px] justify-between">
                {step > 1 ? <div></div> : <div></div>}

                {step === 5 ? (
                  <div className="w-full text-right">
                    <button
                      type="submit"
                      className={`next-first-btn text-[18px] font-circular text-white font-semibold bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] focus:outline-none  focus:ring-offset-2`}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`next-first-btn text-[18px] bg-[${builderConfig?.brandColor}] text-white font-[450] rounded-full flex items-center justify-center`}
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {propertyData?.property_line?.length > 0
                ? propertyData?.property_line
                : "305 Bennett Way"}
            </p>
            <p className="font-circular text-customGray text-[17px]">
              {propertyData?.property_address?.length > 0
                ? propertyData?.property_address
                : "Willow Ridge, Georgia 30132"}
            </p>
            <a className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]">
              See details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoComponent;
