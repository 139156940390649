/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import Loader from "../common/Loader";

function ContractGenerationComponent() {
  const {  user } = useAuth0();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [propertyData, setPropertyData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  
  const [builderInfo, setBuilderInfo] = useState({});
  // const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [builderConfig, setBuilderConfig] = useState({});

  const getUserData = async () => {
    let userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    setUserInfo(userData);
  }
  const getBuilderInfo = async () => {
    let builderValue = localStorage.getItem("builderInfo");
    builderValue = JSON.parse(builderValue);
    setBuilderInfo(builderValue);
  }



  const getBuilderConfig = async () => {
    let builderConfigValue = localStorage.getItem("builderConfig");
    builderConfigValue = JSON.parse(builderConfigValue);
    setBuilderConfig(builderConfigValue);
  }
  const getPropertyData = async () => {
    let propertyValue = localStorage.getItem("propertyInfo");
    propertyValue = JSON.parse(propertyValue);
    setPropertyData(propertyValue);
  }

useEffect(() => {
  const processData = async () => {
    await getBuilderInfo();
          await getBuilderConfig();
          await getPropertyData();
          await getUserData();
          setIsLoading(false);
  };

  processData();
}, []);

  console.log("user iinfo", user);
  const getFormattedDate = () => {
    // Get the current date
    const today = new Date();

    // Extract the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    // Format the date as 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  };
  const submit = async () => {
    setLoading(true);
    const contractData = {
      offerDate: getFormattedDate(),
      seller: {
        name: builderInfo.name,
        line1: builderInfo.address,
        line2: builderInfo.line2,
        city: builderInfo.city,
        state: builderInfo.state,
        pincode: builderInfo.postalCode,
        email: builderInfo.email,
        id: builderInfo.id,
        templateId: builderInfo.templateid,
        clientUserId: builderInfo.builderid,
      },
      buyer: {
        name: userInfo.firstName + " " + userInfo.lastName,
        line1: userInfo.address,
        line2: userInfo.address2,
        city: userInfo.city,
        state: userInfo.state,
        pincode: userInfo.pincode,
        email: userInfo.email,
        clientUserId: user.sub,
      },
      property: {
        address: propertyData.property_address,
        taxParcelID: propertyData.propertyid,
      },
      purchasePrice: propertyData.price,
      earnestMoney: propertyData.earnest_price,
      escrowAgent:
        "Callaway Law, 3330 Cumberland Blvd, Suite 500, Atlanta, Georgia 30339",
      closingLocation:
        "Callaway Law, 3330 Cumberland Blvd, Suite 500, Atlanta, Georgia 30339",
      closingDate: "10/25/2022",
    };
    try {
      const response = await axios.post(`${apiBaseUrl}/contracts`, {
        contractData,
      });
      console.log("contract response", response);
      if (response.status === 201) {
        setLoading(false);
        localStorage.setItem("contractInfo", JSON.stringify(response.data));
        // navigate(response.data.envelope.recipientViewUrl);
        // navigate('/buyer-signing-complete');
        window.location.href = response.data.envelope.recipientViewUrl;
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      // toggleModal();
    }
  };
  const handlePrevious = () => {
    // if (step > 1) {
    // setStep(step - 1);
    // setCurrentStep(currentStep - 1);
    // } else {
    // window.location.replace("/");
    navigate(-1);
    // }
  };


  return  isLoading? ( <Loader /> ) : (
    <div className="min-h-screen flex flex-col items-center">
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[207px] items-center mt-10">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]"></div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <img
              src={builderConfig?.logo}
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">
              Powered by Homesy.
            </p>
          </div>
        </div>
      </div>
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[207px] items-center">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">
              <button
                type="button"
                onClick={handlePrevious}
                className="py-[20px] bg-white text-gray-700  text-[17px]  font-semibold rounded-full flex items-center justify-center"
              >
                <img
                  src="./assets/images/back-arrow-icon.svg"
                  alt="back-icon"
                />{" "}
                Back to all homes
              </button>
              <img
                src={
                  propertyData?.Image_Urls?.length > 0
                    ? propertyData?.Image_Urls
                    : "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                }
                alt={propertyData?.propertyId}
                className="w-[530px] h-[530px] rounded-[20px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <ProgressBar currentStep={6} />
            <h1 className="font-circular text-[#112F50] text-[32px] md:text-[54px] font-[450] md:leading-[64px] text-center mb-[20px] text-[#0E2132]">
              Great, you're approved.
              <br /> Now let's sign the contract.
            </h1>
            <p className="font-circular text-[22px] font-[450] leading-[27.83px] text-center w-full md:w-[572px] mb-[25px] md:mb-[55px] text-customGray">
              To finalize this, we'll need you to sign a contract with <br />{" "}
              the details about the home you're purchasing.
            </p>
            <div className="flex flex-col items-center space-y-6 w-full pl-5 pr-5">
              <div className="w-full text-center flex justify-center">
                {loading ? (
                  <button
                    type="button"
                    className={`start-btn flex justify-center font-circular font-[450] text-white text-[18px] bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] `}
                  >
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />{" "}
                    Processing...
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => submit()}
                    className={`start-btn font-circular font-[450] text-white text-[18px] bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] `}
                  >
                    Sign the contract
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {propertyData?.property_line?.length > 0
                ? propertyData?.property_line
                : "305 Bennett Way"}
            </p>
            <p className="font-circular text-customGray text-[17px]">
              {propertyData?.property_address?.length > 0
                ? propertyData?.property_address
                : "Willow Ridge, Georgia 30132"}
            </p>
            <a className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]">
              See details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractGenerationComponent;
