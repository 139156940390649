import React from "react";

const ProgressBar = ({ currentStep, totalSteps = 7 }) => {
  return (
    <div className="flex items-center justify-between mb-10">
      {[...Array(totalSteps)].map((_, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`w-10 h-1 mr-1 ${
              index < currentStep ? "bg-[#6C1513]" : "bg-[#e9f7ec]"
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
