import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    // navigate("/");
    window.location.href = "https://homesy-41b25f.webflow.io/property";
  };

  return (
    <div>
      <div className="w-full flex items-center justify-center px-8 py-4 bg-white shadow-md">
        <img
          src="./assets/images/main_logo.png"
          alt="logo"
          className="w-24 h-auto"
        />
      </div>

      <div className="flex flex-col items-center mt-20">
        <img className="w-[600px]" src="./assets/images/404.png" />
        <p className="text-xl text-gray-600 mb-8">
          Sorry, we couldn't find this page.
        </p>
        <button
          type="button"
          onClick={handleHomeRedirect}
          className="px-6 py-3 text-white font-semibold bg-main rounded-lg hover:bg-main focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
        >
          Back to homepage
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
