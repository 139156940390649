import React from "react";
import CryptoJS from 'crypto-js';

const ContactForm = () => {
  const secretKey = 'homesy'; // Secret key for encryption

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.target;
        const formData = new FormData(form);
        const dataObject = {};

        // Convert FormData to a plain object
        formData.forEach((value, key) => {
            dataObject[key] = value;
        });

        // Encrypt the entire object as a JSON string
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataObject), secretKey).toString();
        

        // Create the target URL with the encrypted data
        const targetUrl = `/started?data=${encodeURIComponent(encryptedData)}`;

        // Open the new URL in a new tab
        window.open(targetUrl, '_blank');
    };
  return (
    <div className="bg-white shadow-md rounded p-6">
      <h2 className="text-xl font-semibold mb-4">Modern House Real Estate</h2>
      <form id="encodedForm" onSubmit={handleSubmit}>
            <input type="hidden" name="builderID" value="BUILD0001" />
            <input type="hidden" name="propertyid" value="0004" />
            <input
                type="hidden"
                name="property_url"
                value="https://www.lennar.com/new-homes/california/la-orange-county/anaheim/emerald-pointe/emerald-pointe-3m/1155551000"
            />
            <input
                type="hidden"
                name="Image_Urls"
                // value="https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                value="https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
            />
            <input type="hidden" name="property_line" value="305 Bennett Way" />
            <input type="hidden" name="property_address" value="Willow Ridge, Georgia 30132" />
            <input type="hidden" name="price" value="$899990" />
            <input type="hidden" name="earnest_price" value="$20" />
            <button
                className="bg-main hover:bg-main text-white font-bold mt-10 flex items-center py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                type="submit"
            >
                Buy Now from Homesy
            </button>
        </form>


    </div>
  );
};

export default ContactForm;
