import React from "react";
import { Bed, Bath, Car, Square, Calendar } from "lucide-react";

const PropertyDetails = () => {
  return (
    <div className="bg-white shadow-md rounded p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Modern Apartment</h1>
        <div className="text-right">
          <p className="text-xl font-semibold">$450,000</p>
          <p className="text-sm">$2,800/sq ft</p>
        </div>
      </div>
      <p className="text-sm text-gray-600 mb-4">
        4885 N Francisco Ave, Chicago, IL 60625, USA
      </p>
      <div className="mb-4">
        <span className="bg-gray-200 text-gray-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
          FOR SALE
        </span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div className="flex items-center">
          <Bed className="w-6 h-6 mr-2" />
          <p>1 Bedroom</p>
        </div>
        <div className="flex items-center">
          <Bath className="w-6 h-6 mr-2" />
          <p>2 Bathrooms</p>
        </div>
        <div className="flex items-center">
          <Car className="w-6 h-6 mr-2" />
          <p>1 Garage</p>
        </div>
        <div className="flex items-center">
          <Square className="w-6 h-6 mr-2" />
          <p>2149 Sq Ft</p>
        </div>
        <div className="flex items-center">
          <Calendar className="w-6 h-6 mr-2" />
          <p>Year Built: 2016</p>
        </div>
      </div>
      <div className="mb-4">
        <img
          src="./assets/images/home-1.jpg"
          alt="Map"
          className="w-full h-64 object-cover rounded"
        />
      </div>
      <div>
        <h2 className="text-xl font-semibold mb-2">Description</h2>
        <p className="text-gray-700 text-sm">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
          ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
          Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
          molestie consequat, vel illum dolore eu feugiat nulla facilisis at
          vero eros et accumsan et iusto odio dignissim qui blandit praesent
          luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
        </p>

        <p className="text-gray-700 text-sm mt-4">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
          ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
          Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
          molestie consequat, vel illum dolore eu feugiat nulla facilisis at
          vero eros et accumsan et iusto odio dignissim qui blandit praesent
          luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
        </p>
      </div>
    </div>
  );
};

export default PropertyDetails;
