import React, { useEffect } from 'react'
import PropertyDetails from '../components/pages-components/PropertyDetails'
import ContactForm from '../components/pages-components/ContactForm'


function HomePage() {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <PropertyDetails />
        </div>
        <div className="md:col-span-1">
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

export default HomePage