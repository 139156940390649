import { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import HomePage from "./pages/HomePage";
import GettingStarted from "./pages/GettingStarted";
import EarnestMoney from "./pages/EarnestMoney";
import UserForm from "./pages/UserForm";
import ContractGeneration from "./pages/ContractGeneration";
import BuyerSignComplete from "./pages/BuyerSignComplete";
import ErrorPage from "./pages/404";
import TermAndCondition from "./pages/TermAndCondition";
import LoginPage from "./pages/LoginPage";


const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return  (
    <Routes>
      <Route path="/" element={<HomePage  />} />
      <Route path="/started" element={<LoginPage  />} />
      <Route path="/getting-started" element={<GettingStarted  />} />
      <Route path="/user-info" element={<UserForm  />} />
      <Route
        path="/complete"
        element={<BuyerSignComplete  />}
      />
      <Route
        path="/earnest"
        element={<EarnestMoney  />}
      />
      <Route
        path="/contract"
        element={<ContractGeneration  />}
      />
      <Route
        path="/T&C"
        element={<TermAndCondition  />}
      />

      
      <Route path="/404" element={<ErrorPage  />} />

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default App;
