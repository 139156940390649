import React, { useState } from 'react';

const TermAndCondition = () => {
    const [activeTab, setActiveTab] = useState('privacyPolicy'); // Default tab is 'Privacy Policy'

    const renderContent = () => {
        switch (activeTab) {
            case 'privacyPolicy':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
                        <p className="text-gray-600 mb-4">Last Updated: April 19, 2024</p>
                        <h3 className="text-xl font-semibold mb-2">Overview</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dictum, libero non consequat aliquet, felis lacus laoreet risus, non sollicitudin justo nisl sit amet libero. Sed ut sapien nisi. Quisque nec magna eu orci ultricies accumsan. Nam vel ipsum ac nisl bibendum tincidunt. Nulla ac ultricies velit, vitae efficitur nunc. Integer at feugiat velit. Integer consectetur dolor libero, sit amet vulputate odio tempus nec. 
                            Vestibulum vehicula arcu eget dolor tristique fermentum. Proin blandit, mauris id laoreet fringilla, arcu mi vehicula nisi, eget tristique arcu mauris ac magna. Mauris ac ornare dui. Suspendisse commodo gravida turpis sit amet hendrerit. Donec vehicula orci ac felis consequat convallis. Nullam in erat venenatis, facilisis dui in, ornare magna.
                            Aenean fermentum purus non erat tempor ultricies. Nulla sodales tincidunt odio ac vehicula. Curabitur a lorem tincidunt, venenatis est at, consequat leo. Nam suscipit justo lacus, in tempus velit lobortis quis. Cras sagittis felis non sem scelerisque, et iaculis est auctor.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Information Collection</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fermentum libero eu nunc varius, at lacinia nulla viverra. Nam tincidunt neque ut nisl aliquet tincidunt. Proin at lobortis neque, non cursus velit. Pellentesque in dignissim ipsum. Proin ac sagittis quam, ac condimentum ante. Sed luctus tincidunt neque, id tempor sem rhoncus sit amet. Fusce nec quam arcu. Phasellus ac pharetra justo. Nam fermentum, libero nec elementum aliquam, libero sapien tincidunt leo, vitae bibendum quam arcu sed magna.
                            In hac habitasse platea dictumst. Morbi non velit vitae quam pharetra posuere. Ut sollicitudin libero non dolor congue, non iaculis nunc lobortis. Suspendisse rhoncus imperdiet nibh id venenatis. Donec malesuada lacus in tellus facilisis, non rutrum justo posuere.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Data Usage</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed efficitur ex. Morbi vel consequat lacus, sed vestibulum magna. Donec ac metus sit amet felis tincidunt lacinia. Mauris ullamcorper nunc vitae turpis dictum tincidunt. Fusce id tellus suscipit, congue arcu id, lobortis felis. Donec in nunc in turpis laoreet aliquam eget a sapien. Integer euismod libero ac arcu iaculis, ac bibendum nunc tincidunt. 
                            Cras dapibus, metus a finibus tempor, ipsum nisl gravida ligula, id tempus odio justo ut arcu. Curabitur mollis, nulla ut blandit convallis, ligula urna posuere urna, non rutrum lorem arcu vel odio. Nulla scelerisque venenatis lorem, sit amet porttitor mi fermentum a. Phasellus sit amet quam ut lacus feugiat pharetra. Vivamus volutpat fringilla lectus. Phasellus pharetra vestibulum neque, eget varius ex euismod a.
                        </p>
                    </>
                );
            case 'cookiePolicy':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Cookie Policy</h2>
                        <p className="text-gray-600 mb-4">Last Updated: April 10, 2024</p>
                        <h3 className="text-xl font-semibold mb-2">What Are Cookies?</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras lobortis, turpis ut vulputate congue, nunc magna varius felis, nec venenatis nulla magna id mi. Proin auctor risus at massa faucibus, sit amet malesuada enim ultricies. Etiam ut efficitur neque. Ut scelerisque arcu sit amet velit faucibus, quis dignissim libero fermentum. Duis facilisis urna sed ligula vulputate, vel malesuada nunc facilisis. Vestibulum varius id orci id condimentum. Ut nec sem in elit scelerisque viverra. Curabitur dictum massa velit.
                            Mauris ac lobortis odio. Proin ultricies, purus nec egestas sagittis, felis ipsum laoreet ante, ut elementum magna eros eget odio. Nulla auctor magna vitae diam suscipit, sed fringilla orci ultricies. Maecenas fringilla augue sed urna bibendum, at laoreet erat pellentesque. Etiam volutpat orci turpis, ac elementum leo bibendum quis. Fusce consectetur nunc et diam facilisis, a sodales elit rutrum.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">How We Use Cookies</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus est in ante mollis, id luctus erat feugiat. Quisque et nisi fringilla, iaculis libero a, tincidunt ligula. Sed at tellus nec purus bibendum dictum. Nulla vehicula tortor vel lectus vulputate, a vehicula nunc aliquet. Sed ultricies leo at orci viverra, ut efficitur leo malesuada. Ut sed ante vitae turpis pharetra tempor. Etiam suscipit felis nec elit faucibus, ut vulputate orci posuere. Nulla facilisi.
                            Suspendisse potenti. Nunc ac purus in arcu porta porta. Etiam a nulla quis augue iaculis ornare. Suspendisse varius risus magna, at varius libero dictum ac. Vestibulum aliquam pharetra turpis, id luctus nunc venenatis ac. Etiam tincidunt purus eget augue scelerisque, vitae eleifend justo congue. Aliquam erat volutpat.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Managing Cookies</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. In venenatis metus ut pharetra ultricies. Vestibulum pulvinar volutpat purus ac gravida. Mauris nec diam in risus eleifend aliquam. Donec congue, nisl nec finibus facilisis, orci sapien interdum augue, sed sagittis eros purus at quam. Fusce malesuada metus quis nisi vehicula auctor. Vivamus tincidunt felis quis felis feugiat, sit amet aliquet metus eleifend. Pellentesque sit amet lorem eu nunc laoreet fermentum.
                        </p>
                    </>
                );
            case 'termAndConditions':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
                        <p className="text-gray-600 mb-4">Last Updated: March 15, 2024</p>
                        <h3 className="text-xl font-semibold mb-2">Terms</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ac nibh eu ipsum scelerisque viverra. In lacinia dolor nec velit porttitor, vel dictum lacus venenatis. Vivamus vulputate velit in sollicitudin pretium. Nullam vel enim in mauris viverra tempor. Aenean sed dui feugiat, facilisis ligula eget, pulvinar orci. Curabitur at dui sit amet nisi luctus suscipit. Sed ac massa ut purus tincidunt cursus in id ante. Phasellus euismod dui nisi, sit amet facilisis lacus consequat in.
                            Phasellus convallis tincidunt metus, at aliquam justo consequat eget. Nunc fringilla orci vel venenatis fermentum. Aenean malesuada, tortor quis tincidunt consectetur, orci lectus malesuada libero, in dignissim orci justo sit amet risus. Curabitur et aliquet arcu. Mauris ac sem ex. Nulla vulputate, mauris vitae fermentum posuere, ante ipsum vehicula libero, nec luctus enim purus eu justo. Nam aliquet erat id consectetur dictum. Curabitur sit amet augue a nibh malesuada vulputate in sed metus.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Security and Compliance</h3>
                        <p className="text-gray-700 mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor vehicula sapien non mollis. Praesent nec nunc interdum, rhoncus odio ut, malesuada lorem. Curabitur imperdiet orci vel massa congue, non efficitur nisl tempor. Nulla facilisi. Nam dictum arcu ut massa feugiat, sit amet rutrum risus lobortis. Vestibulum finibus, ex sed scelerisque dictum, magna justo cursus lorem, a vulputate enim magna sed sem. Nulla facilisi.
                            Quisque euismod, urna vitae consectetur ullamcorper, mi odio eleifend lorem, non eleifend magna arcu in velit. Etiam in lectus urna. Nulla tincidunt odio id nisl tincidunt laoreet. Etiam tincidunt sapien nec risus dictum lacinia. Mauris euismod justo ut auctor ullamcorper. Nulla suscipit felis libero, sit amet dictum lectus convallis at.
                        </p>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="w-full flex items-center justify-center px-8 py-4 bg-white shadow-md">
                <img
                    src="./assets/images/main_logo.png"
                    alt="logo"
                    className="w-24 h-auto"
                />
            </div>
            <div className="bg-gray-50 text-gray-800 min-h-screen py-10">
                <div className="w-[80%] mx-auto">
                    <div className="flex space-x-10">
                        {/* Sidebar */}
                        <aside className="w-1/4 bg-white p-6 rounded-lg shadow-lg">
                            <nav>
                                <ul>
                                    <li className="mb-4">
                                        <button 
                                            onClick={() => setActiveTab('privacyPolicy')} 
                                            className={`font-semibold ${activeTab === 'privacyPolicy' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                                        >
                                            Privacy Policy
                                        </button>
                                    </li>
                                    <li className="mb-4">
                                        <button 
                                            onClick={() => setActiveTab('cookiePolicy')} 
                                            className={`font-semibold ${activeTab === 'cookiePolicy' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                                        >
                                            Cookie Policy
                                        </button>
                                    </li>
                                    <li className="mb-4">
                                        <button 
                                            onClick={() => setActiveTab('termAndConditions')} 
                                            className={`font-semibold ${activeTab === 'termAndConditions' ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                                        >
                                            Terms and Condition
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </aside>

                        {/* Main Content */}
                        <main className="w-3/4">
                            <div className="bg-white p-8 rounded-lg shadow-lg">
                                <h1 className="text-3xl font-bold mb-6">Terms & Policies</h1>
                                <div className="border-b border-gray-300 mb-4"></div>
                                {renderContent()}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermAndCondition;
